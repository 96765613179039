import DefaultLayout from "../../layout/DefaultLayout"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import Benefits from "./components/Benefits"
import Hero from "./components/Hero"
import ProductsHighlight from "./components/ProductsHighlight"
import useLanding from "./hooks"

const Landing = ({ pageContext, location }) => {
  const {
    content,
    hero,
    listsHighlighted,
  } = useLanding({ pageContext })

  const { productsHighlighted } = pageContext

  return (
    <DefaultLayout pageContext={pageContext} location={location} >
      <SbEditable content={content}>
        <Hero content={hero} />
        <Benefits content={listsHighlighted} location={location} />
        <ProductsHighlight
          content={productsHighlighted?.body}
          title={productsHighlighted?.title}
        />
      </SbEditable>
    </DefaultLayout>
  )
}
export default Landing
